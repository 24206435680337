import React from 'react';
import { Box } from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDotIcon from '../../../assets/svgs/PDCO/TimelineDot';
import styles from '../../PDCO/styles/TimelineDetails.styles';
import TimelineCard from './TimelineCard';

export const BasicTimeline = ({ timelineTrack, option }: any) => {
  return (
    <Box sx={styles.wrapper}>
      <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0
          },
          m: 0,
          py: 0
        }}>
        {timelineTrack?.length > 0 &&
          timelineTrack
            .filter((timelineFact: any) => {
              if (option === 'all') {
                return true;
              }
              return timelineFact.type === option;
            })
            .map((timelineFact: any, index: number) => {
              const isLastItem = index === timelineTrack.length - 1;
              return (
                <TimelineItem key={`${timelineFact.identifier}`}>
                  <TimelineSeparator sx={{ mt: 1.25 }}>
                    <TimelineDotIcon sx={{ m: 0, fontSize: 12 }} />
                    <TimelineConnector
                      sx={{
                        width: '1px',
                        height: isLastItem ? '57%' : '100%',
                        mb: -1.25,
                        flexGrow: 0
                      }}
                    />
                  </TimelineSeparator>
                  <TimelineContent sx={styles.contentWrapper}>
                    <TimelineCard
                      definition={timelineFact.definition}
                      code={timelineFact.identifier}
                      type={timelineFact.type}
                      date={timelineFact.expire_date}
                      productData={timelineFact?.product_data}
                      drugProductFlag={timelineFact.drug_product_flag}
                      drugSubstanceFlag={timelineFact.drug_substance_flag}
                    />
                  </TimelineContent>
                </TimelineItem>
              );
            })}
      </Timeline>
    </Box>
  );
};

export default React.memo(BasicTimeline);
