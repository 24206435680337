/**
 * To be used for results page where the color scheme is determined by one of the attributes in the response
 */
import * as React from 'react';

import Typography from '@mui/material/Typography';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';

import { Box, IconButton, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface GridCellExpandProps {
  value: string;
}

const GridCellExpand = React.memo((props: GridCellExpandProps) => {
  const { value } = props;

  return (
    <>
      {
        // @ts-ignore
        value?.map((data: any) => (
          <Box
            key={data.product_num}
            display='flex'
            flexDirection='row'
            alignItems='center'
            mr={1}
            justifyContent='flex-start'>
            <Typography>{data.product_num}</Typography>
            <Tooltip
              title={
                <Box>
                  <Typography>
                    <b>Product number:</b> {data.product_num}
                  </Typography>
                  <Typography>
                    <b>Strength:</b> {data?.active_ingredients?.[0]?.strength || 'N/A'}
                  </Typography>
                  <Typography>
                    <b>Reference Listed Drug:</b> {data?.reference_drug}
                  </Typography>
                  <Typography>
                    <b>Therapeautic Equivalence:</b> {data?.te_code}
                  </Typography>
                  <Typography>
                    <b>Marketing Status:</b> {data?.marketing_status_description}
                  </Typography>
                </Box>
              }>
              <IconButton>
                <InfoOutlinedIcon sx={{ color: 'gray.700', height: '12px', width: '12px' }} />
              </IconButton>
            </Tooltip>
          </Box>
        ))
      }
    </>
  );
});

const PatentExclusivityProductTag = (params: GridRenderCellParams) => {
  const { value } = params;
  return <GridCellExpand value={value || ''} />;
};

export default PatentExclusivityProductTag;
