import React, { useContext, useEffect, useState } from 'react';
import { Box, Divider, Grid, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import ResultsStore from '../../../../store/SearchResults';
import ResultActions from '../../../../store/SearchResults/actions';
import TimelineView from './TimelineView';
import { VivproSelect } from '../../../../components/CustomComponents/Selects';
import styles from './styles';
import SOURCE_MAPPING from './constant';
import FullScreenProcessing from '../../../SpecialityDesignation/components/FullScreenProcessing';

import GlobalStore from '../../../../store';
import groupIndicationTermByYear from './utils';
import ReportButton from '../../../../components/Report/ReportButton';
import useReportingHandler from '../../../../components/Report/hooks/useReportingHandler';
import getPatentExclusivity from '../../hooks/getPatentExclusivity';
import mergeMatchPatentsExclusivityData, {
  checkForMatchPatentsExclusivity
} from '../../utils/getMatchPatentsExclusivityData';
import applicationCardStyles from '../../styles/ApplicationCard.styles';

const PatentsExclusivityTimeline = ({ setScrollRefElement }: any) => {
  const { resultsState, resultsDispatch } = useContext(ResultsStore);
  const [submissionYearData, setSubmissionYearData] = useState({});
  const [applicationNumberList, setApplicationNumberList] = useState<Array<string>>([]);
  const [applications, setApplication] = useState<Array<any>>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const divRef = React.useRef(null);
  const [source, setSource] = useState<string>('all');
  const [optionList, setOptionList] = useState<Array<any>>([]);
  const { dispatch }: any = useContext(GlobalStore);
  const { updateReportData } = useReportingHandler();

  const getSubmissionData = async () => {
    setLoading(true);
    const searchId = resultsState.searchIds?.applicationSearchId;
    const applicationResults = { ...resultsState.applicationResults };
    if (searchId && !checkForMatchPatentsExclusivity(applicationResults)) {
      const submissionData = await getPatentExclusivity(searchId);

      const newApplData = mergeMatchPatentsExclusivityData(applicationResults, submissionData);
      resultsDispatch({
        type: ResultActions.SET_APPLICATION_RESULTS,
        value: newApplData
      });
    }
    setLoading(false);
  };

  const groupByYear = (data: any) => {
    const groupData = groupIndicationTermByYear(data, source);

    setSubmissionYearData(groupData?.result);
    setApplicationNumberList(groupData?.applList);
    setApplication(groupData?.appArray);
  };

  useEffect(() => {
    getSubmissionData();
  }, []);

  useEffect(() => {
    if (resultsState?.applicationResults) {
      groupByYear(resultsState?.applicationResults);
    }

    const itemList: Array<any> = [
      {
        value: 'all',
        label: 'All Region'
      }
    ];
    Object.keys(resultsState?.applicationResults).forEach((key: string) => {
      if (key in SOURCE_MAPPING) {
        itemList.push({
          value: key,
          label: SOURCE_MAPPING[key]
        });
      }
    });
    setOptionList(itemList);
  }, [resultsState?.applicationResults, source]);

  const handleClick = (e: SelectChangeEvent) => {
    setSource(e.target.value);
  };

  const addToReport = React.useCallback(
    async (_: any, reportData: any) => {
      let referenceText = source === 'all' ? 'US & CA Region' : SOURCE_MAPPING[source];
      if (resultsState?.decryptedPayload?.query) {
        referenceText += ` / ${resultsState?.decryptedPayload?.query}`;
      }
      const sectionLayout = {
        templateType: 'custom',
        chartType: 'PATENT_EXCLUSIVITY_TIMELINE',
        sectionType: 'CHART',
        id: reportData?.reportId,
        style: {
          placement: {
            h: 28,
            w: 12,
            i: reportData?.reportId,
            minW: 8,
            moved: false,
            static: false,
            x: 0,
            y: 0
          },
          graphStyle: {},
          title: {},
          references: {
            show: true,
            href: `${window.location.origin}/search/${resultsState.searchIds?.applicationSearchId}`,
            text: referenceText
          }
        },
        data: {
          source,
          search_id: resultsState.searchIds.applicationSearchId
        }
      };
      updateReportData(reportData?.id, sectionLayout);
    },
    [dispatch, resultsState.searchIds.ctSearchId]
  );

  if (isLoading) {
    return (
      <FullScreenProcessing loading={isLoading} open={isLoading} message='Generating image...' />
    );
  }
  return (
    <Stack width='100%'>
      <Box mr={2} sx={styles.dropDownBox}>
        <Stack direction='row' alignItems='center'>
          <VivproSelect
            // @ts-ignore
            value={source}
            handleChange={handleClick}
            options={optionList}
            icon={KeyboardArrowDown}
          />
          <Stack direction='row' alignItems='center' ml={2} mr={1}>
            <Box sx={styles.patentIcon} />
            <Typography>Patents</Typography>
          </Stack>
          <Divider orientation='vertical' sx={applicationCardStyles.divider} />
          <Stack direction='row' alignItems='center'>
            <Box sx={styles.exclusivityIcon} />
            <Typography>Exclusivity</Typography>
          </Stack>
        </Stack>
        <ReportButton data={{}} addToReport={addToReport} prefixId='patent_exclusivity_timeline_' />
      </Box>
      {Object.keys(submissionYearData).length !== 0 ? (
        <Grid container ref={divRef} mb={3} mt={2} flexWrap='nowrap' sx={styles.gridContainer}>
          <TimelineView
            dataByYear={submissionYearData}
            drugs={applications}
            applicationNumbers={applicationNumberList}
            setScrollRefElement={setScrollRefElement}
            enableDownload
            source={source}
          />
        </Grid>
      ) : (
        <Box sx={styles.message}>
          <Typography variant='h6' color='inherit'>
            No Graphs Available
          </Typography>
        </Box>
      )}
    </Stack>
  );
};

export default PatentsExclusivityTimeline;
