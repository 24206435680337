import React, { useContext, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import Reg360Icon from '../../../../assets/svgs/CardsPage/Reg360Icon';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { infoDiv, infoDivContainer, snackBarContentStyle, snackBarReg360Icon } from './styles';
import ReportButton from '../../../../components/Report/ReportButton';
import ResultsStore from '../../../../store/SearchResults';
import GlobalStore from '../../../../store';
import useReportingHandler from '../../../../components/Report/hooks/useReportingHandler';
import styles from '../../../RegulatoryInformation/PatentExclusivity/TimelineCard.styles';
import { CloseCrossIcon, InfoIconSummary } from '../../../../assets/svgs/Icons';
import TitleInfoPopover from '../../../../components/Dialogs/DocumentListing/components/TitleInfoPopover';
import Card from '../Card';
import SOURCE_MAPPING from './constant';

const ExpandedDrawer = ({ open, onClose, payload, source }: any) => {
  const {
    application,
    type,
    expire_date: expireDate,
    identifier,
    patent_use_code: patentUseCode,
    definition,
    product_data: productData,
    drug_product_flag: drugProductFlag,
    drug_substance_flag: drugSubstanceFlag
  } = payload || {};
  const [infoElement, setInfoElement] = useState(null);

  const title = `${type === 'patent' ? 'Patent' : 'Exclusivity'} Information`;
  const { resultsState } = useContext(ResultsStore);
  const { dispatch }: any = useContext(GlobalStore);
  const { updateReportData } = useReportingHandler();
  const renderProducts = () => {
    return (
      <>
        {productData?.map((data: any) => (
          <Box
            key={data.product_num}
            display='flex'
            flexDirection='row'
            alignItems='center'
            mr={1}
            justifyContent='flex-start'>
            <Typography
              sx={{
                ...styles.secondarySectionText
              }}>
              {data.product_num}
            </Typography>
            <Tooltip
              title={
                <Box>
                  <Typography>
                    <b>Product number:</b> {data.product_num}
                  </Typography>
                  <Typography>
                    <b>Strength:</b> {data?.active_ingredients?.[0]?.strength || 'N/A'}
                  </Typography>
                  <Typography>
                    <b>Reference Listed Drug:</b> {data?.reference_drug}
                  </Typography>
                  <Typography>
                    <b>Therapeautic Equivalence:</b> {data?.te_code}
                  </Typography>
                  <Typography>
                    <b>Marketing Status:</b> {data?.marketing_status_description}
                  </Typography>
                </Box>
              }>
              <IconButton>
                <InfoOutlinedIcon sx={{ color: 'gray.700', height: '12px', width: '12px' }} />
              </IconButton>
            </Tooltip>
          </Box>
        ))}
      </>
    );
  };
  const renderPatentInfo = () => (
    <>
      <Stack width='40%' sx={infoDiv} id='heading'>
        <Box>Type</Box>
        <Divider />
        <Box>Patent Expiry Date</Box>
        <Divider />
        <Box>Patent Number</Box>
        <Divider />
        <Box>Patent Use Code</Box>
        <Divider />
        <Box>Product number</Box>
        <Divider />
        <Box>Description</Box>
      </Stack>
      <Stack width='60%' sx={infoDiv}>
        <Box>
          <Typography
            align='center'
            variant='subtitle2'
            fontWeight='bold'
            id={'patent'}
            sx={{ ...styles.nameTag }}>
            Patent
          </Typography>
          {drugProductFlag ? (
            <Tooltip title={'Drug Product'}>
              <Typography
                align='center'
                variant='subtitle2'
                fontWeight='bold'
                id='type'
                sx={{ ...styles.nameTag }}>
                {'DP'}
              </Typography>
            </Tooltip>
          ) : null}
          {drugSubstanceFlag ? (
            <Tooltip title={'Drug Substance'}>
              <Typography
                align='center'
                variant='subtitle2'
                fontWeight='bold'
                id='type'
                sx={{ ...styles.nameTag }}>
                {'DS'}
              </Typography>
            </Tooltip>
          ) : null}
        </Box>
        <Divider />
        <Box>{expireDate || '-'}</Box>
        <Divider />
        <Box>{identifier || '-'}</Box>
        <Divider />
        <Box>{patentUseCode || '-'}</Box>
        <Divider />
        <Box>{renderProducts()}</Box>
        <Divider />
        <Box>{definition || '-'}</Box>
      </Stack>
    </>
  );

  const renderExclusivityInfo = () => (
    <>
      <Stack width='40%' sx={infoDiv} id='heading'>
        <Box>Type</Box>
        <Divider />
        <Box>Exclusivity Date</Box>
        <Divider />
        <Box>Code</Box>
        <Divider />
        <Box>Product Number</Box>
        <Divider />
        <Box>Definition</Box>
      </Stack>
      <Stack width='60%' sx={infoDiv}>
        <Box>
          <Typography
            align='center'
            variant='subtitle2'
            fontWeight='bold'
            id={'exclusivity'}
            sx={{ ...styles.nameTag }}>
            Exclusivity
          </Typography>
        </Box>
        <Divider />
        <Box>{expireDate || '-'}</Box>
        <Divider />
        <Box>{identifier || '-'}</Box>
        <Divider />
        <Box>{renderProducts()}</Box>
        <Divider />
        <Box>{definition || '-'}</Box>
      </Stack>
    </>
  );
  const addToReport = React.useCallback(
    async (_: any, reportData: any) => {
      let referenceText = source === 'all' ? 'US & CA Region' : SOURCE_MAPPING[source];
      if (resultsState?.decryptedPayload?.query) {
        referenceText += ` / ${resultsState?.decryptedPayload?.query}`;
      }
      const sectionLayout = {
        templateType: 'custom',
        chartType: 'PATENT_EXCLUSIVITY_SUMMARY',
        sectionType: 'CHART',
        id: reportData?.reportId,
        style: {
          placement: {
            h: 28,
            w: 12,
            i: reportData?.reportId,
            minW: 8,
            moved: false,
            static: false,
            x: 0,
            y: 0
          },
          graphStyle: {},
          title: {},
          references: {
            show: true,
            href: `${window.location.origin}/search/${resultsState.searchIds?.applicationSearchId}`,
            text: referenceText
          }
        },
        data: {
          data:
            type === 'patent'
              ? [
                  {
                    'Patent Expiry Date': expireDate,
                    'Patent Number': identifier,
                    'Patent Use Code': patentUseCode,
                    'Product number': productData?.map((data: any) => data.product_num).join(', '),
                    Description: definition
                  }
                ]
              : [
                  {
                    'Exclusivity Date': expireDate,
                    Code: identifier,
                    'Product number': productData?.map((data: any) => data.product_num).join(', '),
                    Definition: definition
                  }
                ],

          title: title
        }
      };
      updateReportData(reportData?.id, sectionLayout);
    },
    [dispatch, resultsState.searchIds.ctSearchId]
  );

  return (
    <Drawer anchor='right' open={open} onClose={onClose} sx={{ width: '400px' }}>
      <Stack sx={snackBarContentStyle}>
        <Box bgcolor='primary.600' py={1.5} px={2.5}>
          <Stack direction='row' alignItems='center'>
            <Tooltip title={application?.title}>
              <Typography sx={{ ...styles.drawerTitle, maxWidth: !infoElement ? '90%' : '45%' }}>
                {application?.title}
              </Typography>
            </Tooltip>

            <IconButton
              sx={{ p: 0.75, ml: 1 }}
              onClick={(event: any) => setInfoElement(event.currentTarget)}>
              <InfoIconSummary sx={styles.drawerHeaderIcon} />
            </IconButton>
            <IconButton sx={styles.closeIconButton} onClick={onClose}>
              <CloseCrossIcon sx={styles.drawerHeaderIcon} />
            </IconButton>
          </Stack>
          <TitleInfoPopover
            open={!!infoElement}
            anchorEl={infoElement}
            infoComponent={
              <Box sx={styles.cardInfoComponent}>
                <Card
                  data={application}
                  hideApplication={() => {}}
                  triggerChatRIA={() => {}}
                  setFavoriteInHook={() => {}}
                  setInProjectInHook={() => {}}
                  setSubscriptionInHook={() => {}}
                  hideCardOptions
                />
              </Box>
            }
            onClose={() => setInfoElement(null)}
          />
        </Box>
        <Stack direction='row' justifyContent='space-between' px='20px' alignItems='center' mt={2}>
          <Button
            sx={snackBarReg360Icon}
            startIcon={<Reg360Icon />}
            onClick={() => {
              window.open(
                `/regulatory360/core/${encodeURIComponent(application?.identifier)}/${
                  application?.source
                }`,
                '_blank'
              );
            }}>
            Open Reg 360
          </Button>
          <ReportButton
            data={{}}
            addToReport={addToReport}
            prefixId='patent_exclusivity_summary_'
          />
        </Stack>
        <Stack
          direction='row'
          justifyContent='space-between'
          gap='12px'
          px='20px'
          mt='8px'
          sx={infoDivContainer}>
          {type === 'patent' ? renderPatentInfo() : renderExclusivityInfo()}
        </Stack>
      </Stack>
    </Drawer>
  );
};

export default ExpandedDrawer;
