import React, { useContext } from 'react';

// muiv5
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import VivproDatagrid from '../../../components/Datagrid';
import EmptyPage from '../../../components/EmptyPage/EmptyPage';
// context
import RegulatoryInformationStore from '../../../store/RegulatoryInformation';

// hoc
import withFetchingPatentExclusivityDetails from './withFetchingPatentExclusivityDetails';
import Timeline from './Timeline';
import { PatentExclusivity } from './constants';
import styles from '../../../components/Timeline/TimelineDocuments/TimelineDocuments.styles';
import Card from '../../SearchResults/components/Card';

const PatentExclusivityDetails = () => {
  const { regulatoryState, regulatoryDispatch } = useContext(RegulatoryInformationStore);

  return (
    <Box display='flex' justifyContent='center' sx={{ width: '100%', height: '100%' }}>
      {!regulatoryState?.loadingPatentExclusivity &&
      regulatoryState.patent_exclusivity?.length <= 0 ? (
        <EmptyPage message='Exclusivity are not available' />
      ) : (
        <Stack flexDirection='row' sx={{ height: '60vh', width: 'calc(100% - 24px)' }}>
          <VivproDatagrid
            rows={regulatoryState?.patentExclusivity}
            columnsMapping={PatentExclusivity}
            rowId='identifier'
            loading={regulatoryState?.loadingPatentExclusivity}
          />
          <Timeline
            isOpen={regulatoryState?.showTimeline}
            closeTimeline={() => {
              regulatoryDispatch({
                type: 'SET_SHOW_TIMELINE',
                value: false
              });
            }}
            timelineDetails={{
              timeline: regulatoryState?.patentExclusivity,
              applicationDetails: regulatoryState?.applicationDetails
            }}
            onClose={() =>
              regulatoryDispatch({
                type: 'SET_SHOW_TIMELINE',
                value: false
              })
            }
            infoComponent={
              <Box sx={styles.cardInfoComponent}>
                <Card
                  data={regulatoryState.applicationDetails}
                  hideApplication={() => {}}
                  triggerChatRIA={() => {}}
                  setFavoriteInHook={() => {}}
                  setInProjectInHook={() => {}}
                  setSubscriptionInHook={() => {}}
                  hideCardOptions
                />
              </Box>
            }
          />
        </Stack>
      )}
    </Box>
  );
};

export default withFetchingPatentExclusivityDetails(PatentExclusivityDetails);
