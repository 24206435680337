import React, { memo, useState } from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  Stack,
  Divider,
  IconButton
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { format } from 'date-fns';
import { HideIcon, ViewIcon } from '../../../assets/svgs/Icons';

import styles from './TimelineCard.styles';

const TimelineCard = ({
  definition,
  code,
  type,
  date,
  productData,
  drugProductFlag,
  drugSubstanceFlag
}: any) => {
  const [expanded, setExpanded] = useState(false);

  const handleViewSummary = () => {
    setExpanded(prev => !prev);
  };

  const getSummaryTitle = () => {
    if (definition && definition === 'NA') {
      return 'No summary available from the source';
    }
    if (expanded) {
      return 'Hide Summary';
    }
    return 'View Summary';
  };

  return (
    <Box
      sx={{
        ...styles.wrapper,
        borderColor: 'gray.200'
      }}>
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Box flexShrink={0} width={100}>
          <Typography sx={styles.yearText}>{format(new Date(date), 'YYY, MMM dd')}</Typography>
        </Box>
        <Box
          display='flex'
          flexGrow={1}
          flexDirection='row'
          alignItems='center'
          justifyContent='flex-start'
          ml={2}>
          <Tooltip title={code} placement='top'>
            <Typography sx={{ ...styles.sectionText, maxWidth: { md: 200, lg: 285, xl: '100%' } }}>
              {code}
            </Typography>
          </Tooltip>
        </Box>
        <Stack direction='row' gap={0.5} flexShrink={0}>
          <Typography
            align='center'
            variant='subtitle2'
            fontWeight='bold'
            id={type}
            sx={{ ...styles.nameTag }}>
            {type}
          </Typography>
          {drugProductFlag ? (
            <Tooltip title={'Drug Product'}>
              <Typography
                align='center'
                variant='subtitle2'
                fontWeight='bold'
                id='type'
                sx={{ ...styles.nameTag }}>
                {'DP'}
              </Typography>
            </Tooltip>
          ) : null}
          {drugSubstanceFlag ? (
            <Tooltip title={'Drug Substance'}>
              <Typography
                align='center'
                variant='subtitle2'
                fontWeight='bold'
                id='type'
                sx={{ ...styles.nameTag }}>
                {'DS'}
              </Typography>
            </Tooltip>
          ) : null}
        </Stack>
      </Stack>
      <Divider sx={{ my: 1, borderColor: 'gray.200' }} />
      <Accordion
        expanded={expanded}
        onChange={() => {
          handleViewSummary();
        }}
        defaultExpanded
        sx={styles.accordion}>
        <AccordionSummary
          expandIcon={
            expanded ? (
              <HideIcon
                sx={{
                  ...styles.hideIcon,
                  color: 'primary.700'
                }}
              />
            ) : (
              <ViewIcon
                sx={{
                  ...styles.hideIcon,
                  color: 'primary.700'
                }}
              />
            )
          }
          aria-controls={`${date}-${code}`}
          id={`${date}-${code}`}
          sx={styles.accordionSummary}>
          <Typography
            fontWeight={500}
            sx={{
              ...styles.hideText,
              color: 'primary.700'
            }}>
            {getSummaryTitle()}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={styles.accordionDetails2}>
          <Stack direction='row' alignItems='center'>
            <Box flexShrink={0} mr={2} width={100}>
              <Typography sx={styles.productText}>Product no</Typography>
            </Box>
            {productData.map((data: any) => (
              <Box
                key={data.product_num}
                display='flex'
                flexDirection='row'
                alignItems='center'
                mr={2}
                justifyContent='flex-start'>
                <Typography
                  sx={{
                    ...styles.secondarySectionText
                  }}>
                  {data.product_num}
                </Typography>
                <Tooltip
                  title={
                    <Box>
                      <Typography variant='body2'>
                        <b>Product number:</b> {data.product_num}
                      </Typography>
                      <Typography variant='body2'>
                        <b>Strength:</b> {data?.active_ingredients?.[0]?.strength || 'N/A'}
                      </Typography>
                      <Typography variant='body2'>
                        <b>Reference Listed Drug:</b> {data?.reference_drug}
                      </Typography>
                      <Typography variant='body2'>
                        <b>Therapeautic Equivalence:</b> {data?.te_code}
                      </Typography>
                      <Typography variant='body2'>
                        <b>Marketing Status:</b> {data?.marketing_status_description}
                      </Typography>
                    </Box>
                  }>
                  <IconButton>
                    <InfoOutlinedIcon sx={{ color: 'gray.700', height: '12px', width: '12px' }} />
                  </IconButton>
                </Tooltip>
              </Box>
            ))}
          </Stack>
          <Divider sx={{ my: '12px', borderColor: 'gray.200' }} />
          <Typography sx={styles.secondaryText}>Definition</Typography>
          <Typography sx={styles.accordionSummaryText} mt='8px' mb='12px'>
            {definition || 'N/A'}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default memo(TimelineCard);
