import React, { useEffect, useRef, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import TimelineBadge from './TimelineBadge';
import Cards from './Cards';
import ExpandedDrawer from './ExpandedDrawer';
import styles, { horizontalTimelineStyle, plusStyle } from './styles';

const TimelineView = ({
  dataByYear,
  onUpdatePlacement,
  id,
  setScrollRefElement,
  enableDownload = false,
  isReport = false,
  source
}: any) => {
  const cardsRef = useRef<HTMLDivElement>(null);
  const centerRef = useRef<HTMLDivElement>(null);
  const viewRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);

  const [scale, setScale] = useState(0.8);
  const [isDragging, setIsDragging] = useState(false);
  const [lastPosition, setLastPosition] = useState({ x: 0, y: 0 });
  const [isExpandedCardOpen, setIsExpandedCardOpen] = useState(false);
  const [activeBadge, setActiveBadge] = useState('');
  const [expandedPayload, setExpandedPayload] = useState({});
  const [drugs, setDrugs] = useState([]);

  const handleClose = () => {
    setIsExpandedCardOpen(false);
  };

  const handleMouseDown = (event: MouseEvent) => {
    event.preventDefault();
    setIsDragging(true);
    setLastPosition({ x: event.clientX, y: event.clientY });
  };

  const handleMouseMove = (event: MouseEvent) => {
    event.preventDefault();
    if (!isDragging) return;

    const { scrollTop, scrollLeft } = scrollRef.current!;
    scrollRef.current!.scrollTop = scrollTop - (event.clientY - lastPosition.y);
    scrollRef.current!.scrollLeft = scrollLeft - (event.clientX - lastPosition.x);

    setLastPosition({ x: event.clientX, y: event.clientY });
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleResize = () => {
    const widthtimline = centerRef?.current?.clientWidth || 0;
    const width = viewRef?.current?.clientWidth || 0;
    const clientwidth = widthtimline * Object.keys(dataByYear).length + (width + 30);
    const totalWidth = document.getElementById(id)?.offsetWidth ?? 0;
    const newScale = totalWidth / clientwidth;

    if (newScale >= 1) {
      setScale(0.8);
    } else {
      setScale(newScale);
    }
  };

  useEffect(() => {
    if (isReport && drugs.length !== 0) {
      handleResize();
    }
  }, [drugs, document.getElementById(id)?.offsetWidth]);

  useEffect(() => {
    if (enableDownload) {
      setScrollRefElement(scrollRef);
    }
  }, [scrollRef]);

  useEffect(() => {
    if (isReport && drugs.length !== 0) {
      const scrollElement = cardsRef?.current;
      if (!scrollElement) return;

      const clientHeight = scrollElement?.clientHeight || 0;
      const computedStyle = window.getComputedStyle(scrollElement);
      const marginBottomValue = parseFloat(computedStyle?.marginBottom) || 0;

      const totalHeight = (clientHeight + marginBottomValue) * scale * drugs.length + 50;
      onUpdatePlacement?.(Math.ceil(totalHeight / 10) + 1);
    }
  }, [drugs, scale]);

  // Ensure the positions are calculated after all elements are rendered
  useEffect(() => {
    const finalDrug: any = {};
    Object.values(dataByYear).forEach((data: any) => {
      Object.entries(data).forEach(([key, { application }]: any) => {
        finalDrug[key] = application;
      });
    });
    setDrugs(Object.values(finalDrug));
  }, [dataByYear]);

  return (
    // @ts-ignore
    <Stack
      direction='row'
      width={isReport ? '100%' : 'auto'}
      height='100%'
      ref={scrollRef}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      sx={{
        ...horizontalTimelineStyle,
        '&>div': {
          transform: isReport ? `scale(${scale})` : null,
          transformOrigin: 'left top',
          transition: 'transform 0.3s ease-in-out',
          minWidth: isReport ? `${scale * 200}px` : '180px'
        }
      }}>
      <Stack sx={styles.timeLineCards} ref={viewRef} height='100%'>
        {drugs.length !== 0 &&
          drugs.map((drug: any) => (
            <Cards
              key={drug?.identifier}
              tradeName={drug?.title}
              applicationType={drug?.labels ? drug?.labels[0] : drug?.colorScheme}
              cardsRef={cardsRef}
              applicationId={drug.identifier}
              applicationData={drug}
            />
          ))}
      </Stack>
      <Stack flexDirection='row' height='100%'>
        {Object.entries(dataByYear)
          .sort(([yearA], [yearB]) => parseInt(yearA, 10) - parseInt(yearB, 10))
          .map(([year, data]: any) => {
            return (
              <Stack
                direction='column'
                alignItems='center'
                key={year}
                minWidth={isReport ? 'auto' : '115px !important'}
                id={`year-${year}`}
                ref={centerRef}>
                <Stack
                  direction='row'
                  justifyContent='center'
                  alignItems='center'
                  width='100%'
                  height={24}
                  sx={{
                    ...styles.yearBox,
                    position: isReport ? 'relative' : 'sticky'
                  }}>
                  <Box>
                    <Typography sx={styles.yearText} variant='caption'>
                      {year === '1900' ? 'NA' : year}
                    </Typography>
                  </Box>
                </Stack>

                <Stack display='flex' justifyContent='center' flexDirection='column' width='100%'>
                  {drugs.map((drug: any) => {
                    const applicationId = drug.identifier;
                    const application = drug;
                    const patentExclusivity = data[applicationId]?.patentExclusivity;

                    return (
                      <Box
                        className='eplus'
                        key={`${applicationId}-${year}`}
                        id={`plus-item-${applicationId}-${year}`}
                        sx={plusStyle}>
                        <Box sx={{ zIndex: 1, position: 'relative' }} />
                        {patentExclusivity && (
                          <TimelineBadge
                            application={application}
                            patentExclusivity={patentExclusivity}
                            activeBadge={activeBadge}
                            isExpandedCardOpen={isExpandedCardOpen}
                            setIsExpandedCardOpen={setIsExpandedCardOpen}
                            setActiveBadge={setActiveBadge}
                            setExpandedPayload={setExpandedPayload}
                          />
                        )}
                      </Box>
                    );
                  })}
                </Stack>
              </Stack>
            );
          })}
      </Stack>

      <ExpandedDrawer
        open={isExpandedCardOpen}
        payload={{ ...expandedPayload }}
        onClose={handleClose}
        source={source}
      />
    </Stack>
  );
};

export default React.memo(TimelineView);
