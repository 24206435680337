import React, { useState } from 'react';
import { Stack, Typography, Box, Tooltip, Chip, IconButton } from '@mui/material';
import styles, { CardContainer } from './styles';
import { LABEL_COLOR, LABEL_TOOLTIP_TITLE } from '../../constants';
import { InfoIconSummary } from '../../../../assets/svgs/Icons';
import TitleInfoPopover from '../../../../components/Dialogs/DocumentListing/components/TitleInfoPopover';
import Card from '../Card';

const Cards = ({
  tradeName,
  applicationType,
  cardsRef,
  applicationId,
  applicationData = {}
}: {
  tradeName: string;
  applicationType: string;
  cardsRef: React.RefObject<HTMLDivElement>;
  applicationId: string;
  applicationData?: any;
}) => {
  const [infoElement, setInfoElement] = useState(null);

  const labelKey = applicationType?.toLowerCase();
  const labelColor = LABEL_COLOR[labelKey];
  const labelTooltipTitle = LABEL_TOOLTIP_TITLE[labelKey];
  return (
    <Stack sx={CardContainer} ref={cardsRef} id={`application-${applicationId}`}>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Tooltip title={tradeName as string}>
          <Typography
            sx={{
              color: labelColor,
              ...styles.tradeName
            }}>
            {tradeName}
          </Typography>
        </Tooltip>
        {applicationData && (
          <>
            <IconButton
              sx={{ p: 0.75, ml: 1 }}
              onClick={(event: any) => setInfoElement(event.currentTarget)}>
              <InfoIconSummary sx={{ fontSize: 12 }} />
            </IconButton>
            <TitleInfoPopover
              open={!!infoElement}
              anchorEl={infoElement}
              infoComponent={
                <Box width={360}>
                  <Card
                    data={applicationData}
                    hideApplication={() => {}}
                    triggerChatRIA={() => {}}
                    setFavoriteInHook={() => {}}
                    setInProjectInHook={() => {}}
                    setSubscriptionInHook={() => {}}
                    hideCardOptions
                  />
                </Box>
              }
              onClose={() => setInfoElement(null)}
            />
          </>
        )}
        <Tooltip title={labelTooltipTitle}>
          <Chip
            label={applicationType}
            key={applicationType}
            sx={{
              ...styles.chipMetadata,
              bgcolor: labelColor
            }}
          />
        </Tooltip>
      </Box>
    </Stack>
  );
};

export default React.memo(Cards);
