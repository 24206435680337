/* eslint-disable import/prefer-default-export */
import { DocumentHeadings, MetadataKeys } from './types/documentResultsUtils.types';

import mapUSApps, { USA_IDENTIFIER_KEY } from './utils/mapUSApps';
import mapEMAApps, { EMA_IDENTIFIER_KEY } from './utils/mapEMAApps';
import mapCAApps, { CA_IDENTIFIER_KEY } from './utils/mapCAApps';
import mapJpApps, { JP_IDENTIFIER_KEY } from './utils/mapJpApps';
import mapHMAApps, { HMA_IDENTIFIER_KEY } from './utils/mapHMAApps';

import {
  BestMatchIcon,
  CanadaFlagIcon,
  EuFlagIcon,
  HighScoreConfidence,
  IrelandFlagIcon,
  JapanFlagIcon,
  LowScoreConfidence,
  MediumScoreConfidence,
  UsaFlagIcon,
  ViewLabelsIcon,
  EnhancedComparisonIcon,
  AustraliaFlagIcon,
  GridVariantIcon,
  ListIcon,
  UKFlagIcon,
  IchFlagIcon
} from '../../assets/svgs/Icons';
import mapHPRAApps, { HPRA_IDENTIFIER_KEY } from './utils/mapHPRAApps';
import mapDevicesApps, { DEVICES_IDENTIFIER_KEY } from './utils/mapDevicesApps';
import RESULT_VIEW_TYPES from './components/constants';
import mapCTApps, { CT_IDENTIFIER_KEY } from './utils/mapCTApps';
import mapAuApps, { AU_IDENTIFIER_KEY } from './utils/mapAuApps';
import WarningIcon from '../../assets/svgs/Icons/WarningIcon';
import mapMHRAApps, { MHRA_IDENTIFIER_KEY } from './utils/mapMHRAApps';
import ArgFlagIcon from '../../assets/svgs/Flag/ArgFlagIcon';
import BraFlagIcon from '../../assets/svgs/Flag/BraFlagIcon';
import MaxFlagIcon from '../../assets/svgs/Flag/MaxFlagIcon';
import EgyFlagIcon from '../../assets/svgs/Flag/EgyFlagIcon';
import SgpFlagIcon from '../../assets/svgs/Flag/SgpFlagIcon';
import JpnFlagIcon from '../../assets/svgs/Flag/JpnFlagIcon';
import KorFlagIcon from '../../assets/svgs/Flag/KorFlagIcon';
import ChnFlagIcon from '../../assets/svgs/Flag/ChnFlagIcon';
import SauFlagIcon from '../../assets/svgs/Flag/SauFlagIcon';
import CheFlagIcon from '../../assets/svgs/Flag/CheFlagIcon';
import TwFlagIcon from '../../assets/svgs/Flag/TwFlagIcon';
import TurFlagIcon from '../../assets/svgs/Flag/TurFlagIcon';

// Define METADATA_KEYS with the MetadataKeys interface
export const METADATA_KEYS: MetadataKeys = {
  ...Object.assign(
    {},
    ...['CDER-SBA', 'CBER-SBA', 'CDER-EUA'].map(k => ({
      [k]: [
        { id: 'approval_date', label: 'Approval Date' },
        {
          id: 'application_number',
          label: 'Application Number'
        },
        { id: 'submission_label', label: 'Submission' },
        { id: 'category', label: 'Category' }
      ]
    }))
  ),
  'SEC Edgar': [
    { id: 'filing_date', label: 'Filing Date' },
    {
      id: 'entity_id',
      label: 'Accession Number'
    },
    { id: 'file_number', label: 'File number' },
    { id: 'ciks', label: 'CIK' }
  ],
  ...Object.assign(
    {},
    ...['EMA EPAR', 'EMA WHO'].map(k => ({
      [k]: [
        { id: 'approval_date', label: 'Approval Date' },
        {
          id: 'application_number',
          label: 'Product Number'
        },
        { id: 'category', label: 'Category' }
      ]
    }))
  ),
  ...Object.assign(
    {},
    ...['Guidances', 'PSG-Guidance'].map(k => ({
      [k]: [
        { id: 'field_issue_datetime', label: 'Issue Date' },
        {
          id: 'field_docket_number',
          label: 'Docket Number'
        },
        { id: 'field_regulated_product_field', label: 'Regulated Project Field' }
      ]
    }))
  ),
  ...Object.assign(
    {},
    ...['SOPP'].map(k => ({
      [k]: [{ id: 'field_issue_datetime', label: 'Issue Date' }]
    }))
  ),
  ...Object.assign(
    {},
    ...['MAPP'].map(k => ({
      [k]: [
        { id: 'field_issue_datetime', label: 'Issue Date' },
        { id: 'field_topics', label: 'Topic' },
        { id: 'field_center', label: 'Center' }
      ]
    }))
  ),
  ...Object.assign(
    {},
    ...['EMA Guidances'].map(k => ({
      [k]: [
        { id: 'last_published_date', label: 'Published Date' },
        {
          id: 'ema_document_no',
          label: 'EMA Document Number'
        },
        { id: 'status', label: 'Status' },
        { id: 'document_type', label: 'Document Type' }
      ]
    }))
  ),
  ...Object.assign(
    {},
    ...['CA Guidances'].map(k => ({
      [k]: [
        { id: 'issued_date', label: 'Document Date' },
        {
          id: 'last_modified_date',
          label: 'Last Updated Date'
        },
        { id: '_category', label: 'Category' }
      ]
    }))
  ),
  ...Object.assign(
    {},
    ...['CA Consultations'].map(k => ({
      [k]: [
        { id: 'start_date', label: 'Start Date' },
        {
          id: 'end_date',
          label: 'End Date'
        },
        { id: 'document_type', label: 'Category' }
      ]
    }))
  ),
  ...Object.assign(
    {},
    ...['FDA Written Requests'].map(k => ({
      [k]: [
        { id: 'determination_date', label: 'Determination Date' },
        {
          id: 'drug_name',
          label: 'Drug Name'
        },
        { id: 'wr_sponsor', label: 'Written Request Sponsor' },
        { id: '_category', label: 'Category' }
      ]
    }))
  ),
  ...Object.assign(
    {},
    ...['PIP'].map(k => ({
      [k]: [
        { id: 'decision_date', label: 'Decision Date' },
        {
          id: 'decision-number',
          label: 'Decision Number'
        },
        { id: 'formulation', label: 'Formulation' },
        { id: 'route', label: 'Route' }
      ]
    }))
  ),
  ...Object.assign(
    {},
    ...['AdComm-CDER'].map(k => ({
      [k]: [
        { id: 'adcom_meeting_start_date', label: 'Adcomm Meeting Start Date' },
        {
          id: 'application_number',
          label: 'Application Number'
        },
        { id: 'meeting_id', label: 'Meeting ID' },
        { id: 'category', label: 'Document Type' }
      ]
    }))
  ),
  ...Object.assign(
    {},
    ...['AdComm-CDRH'].map(k => ({
      [k]: [
        { id: 'adcom_meeting_start_date', label: 'Adcomm Meeting Start Date' },
        { id: 'meeting_id', label: 'Meeting ID' },
        { id: 'category', label: 'Document Type' }
      ]
    }))
  ),
  ...Object.assign(
    {},
    ...['AdComm-CBER'].map(k => ({
      [k]: [
        { id: 'adcom_meeting_start_date', label: 'Adcomm Meeting Start Date' },
        { id: 'meeting_id', label: 'Meeting ID' },
        { id: 'category', label: 'Document Type' }
      ]
    }))
  ),
  ...Object.assign(
    {},
    ...['CHMP'].map(k => ({
      [k]: [
        { id: 'first-published', label: 'First Published' },
        { id: 'last-updated', label: 'Last Updated Date' }
      ]
    }))
  ),
  ...Object.assign(
    {},
    ...['HPFB'].map(k => ({
      [k]: [
        { id: 'submission-approval-date', label: 'Approval Date' },
        {
          id: 'vin-number',
          label: 'Vin Number'
        },
        { id: 'brand-name', label: 'Brand Name' },
        { id: '_category', label: 'Category' }
      ]
    }))
  ),
  ...Object.assign(
    {},
    ...['HMA'].map(k => ({
      [k]: [
        { id: 'approval_date', label: 'Approval Date' },
        {
          id: 'product_number',
          label: 'Product Number'
        },
        { id: 'sponsor', label: 'Sponsor Name' },
        { id: 'category', label: 'Category' }
      ]
    }))
  ),
  ...Object.assign(
    {},
    ...['HPRA'].map(k => ({
      [k]: [
        { id: 'approval-date', label: 'Approval Date' },
        {
          id: 'product-number',
          label: 'Product Number'
        },
        { id: 'sponsor', label: 'Sponsor Name' },
        { id: '_category', label: 'Category' }
      ]
    }))
  ),
  ...Object.assign(
    {},
    ...['CLINICAL_TRIALS'].map(k => ({
      [k]: [
        { id: 'start-date', label: 'Start Date' },
        { id: 'nct_id', label: 'NCT ID' },
        {
          id: 'phase',
          label: 'Phase'
        },
        { id: '_category', label: 'Category' }
      ]
    }))
  ),
  ...Object.assign(
    {},
    ...['EU CLINICAL TRIALS'].map(k => ({
      [k]: [
        { id: 'start_date', label: 'Start Date' },
        { id: 'euct_id', label: 'EUCT ID' },
        {
          id: 'phase',
          label: 'Phase'
        },
        { id: 'category', label: 'Category' }
      ]
    }))
  ),
  ...Object.assign(
    {},
    ...['CDRH-SBA', 'CDRH-EUA'].map(k => ({
      [k]: [
        { id: 'approval_date', label: 'Approval Date' },
        {
          id: 'application_number',
          label: 'Application Number'
        },
        { id: 'category', label: 'Category' }
      ]
    }))
  ),
  ...Object.assign(
    {},
    ...['CLINICAL TRIALS'].map(k => ({
      [k]: [
        { id: 'start_date', label: 'Start Date' },
        { id: 'nct_id', label: 'NCT ID' },
        {
          id: 'phase',
          label: 'Phase'
        },
        { id: 'category_bucket', label: 'Document Type' }
      ]
    }))
  ),
  ...Object.assign(
    {},
    ...['PMDA'].map(k => ({
      [k]: [
        { id: 'approval-date', label: 'Approval Date' },
        {
          id: 'yj_code',
          label: 'Application number'
        },
        { id: 'trade-name', label: 'Trade Name' },
        { id: '_category', label: 'Category' }
      ]
    }))
  ),
  ...Object.assign(
    {},
    ...['FDA Letters'].map(k => ({
      [k]: [
        {
          id: 'issue_date',
          label: 'Issue Date'
        },
        { id: '_category', label: 'Category' },
        { id: 'company_name', label: 'Sponsor' }
      ]
    }))
  ),
  ...Object.assign(
    {},
    ...['NICE'].map(k => ({
      [k]: [
        {
          id: 'published',
          label: 'Published Date'
        },
        {
          id: 'reference_number',
          label: 'Reference number'
        },
        { id: 'document_type', label: 'Document Type' },
        { id: 'document_category', label: 'Document Category' }
      ]
    }))
  ),
  ...Object.assign(
    {},
    ...['DDT'].map(k => ({
      [k]: [
        {
          id: 'project_number',
          label: 'Project Number'
        },
        { id: 'stage_date', label: 'Stage Date' },
        { id: '_category', label: 'Stage' },
        { id: 'category_bucket', label: 'Document Type' }
      ]
    }))
  ),
  ...Object.assign(
    {},
    ...['EMA ORPHAN DESIGNATION', 'EU ORPHAN DESIGNATION'].map(k => ({
      [k]: [
        { id: 'document-info', label: 'Procedure Type/Status' },
        { id: 'approval-date', label: 'Approval Date' },
        { id: 'category-bucket', label: 'Category' }
      ]
    }))
  ),
  ...Object.assign(
    {},
    ...['MHRA'].map(k => ({
      [k]: [
        { id: 'vin', label: 'Vin Number' },
        { id: 'approval_date', label: 'Approval Date' },
        { id: '_category', label: 'Category' }
      ]
    }))
  ),
  ...Object.assign(
    {},
    ...['Australia TGA'].map(k => ({
      [k]: [
        { id: 'start_date', label: 'Start Date' },
        { id: 'vin', label: 'Vin Number' },
        {
          id: 'product_name',
          label: 'Trade Name'
        },
        { id: 'category_bucket', label: 'Category' }
      ]
    }))
  ),
  ...Object.assign(
    {},
    ...['ICH-Guideline'].map(k => ({
      [k]: [
        { id: 'step_date', label: 'Step Date' },
        { id: '_category', label: 'Category' },
        { id: 'doc_type', label: 'Document Type' }
      ]
    }))
  ),
  ...Object.assign(
    {},
    ...['PRAC'].map(k => ({
      [k]: [
        { id: 'first-published', label: 'First Published Date' },
        { id: 'last-updated', label: 'Last Updated Date' },
        { id: 'category-bucket', label: 'Category' }
      ]
    }))
  ),
  ...['PDCO'].map(k => ({
    [k]: [
      { id: 'first-published', label: 'First Published Date' },
      { id: 'last-updated', label: 'Last Updated Date' },
      { id: 'category-bucket', label: 'Category' }
    ]
  }))
  // Add other countries or sources as needed
};

export const apiDataToCardProps: any = {
  eu: mapEMAApps,
  us: mapUSApps,
  ca: mapCAApps,
  hpra: mapHPRAApps,
  'us-devices': mapDevicesApps,
  jp: mapJpApps,
  hma: mapHMAApps,
  ct: mapCTApps,
  au: mapAuApps,
  mhra: mapMHRAApps
};

export const identifierKey: { [key: string]: string } = {
  eu: EMA_IDENTIFIER_KEY,
  us: USA_IDENTIFIER_KEY,
  ca: CA_IDENTIFIER_KEY,
  hpra: HPRA_IDENTIFIER_KEY,
  'us-devices': DEVICES_IDENTIFIER_KEY,
  jp: JP_IDENTIFIER_KEY,
  hma: HMA_IDENTIFIER_KEY,
  ct: CT_IDENTIFIER_KEY,
  au: AU_IDENTIFIER_KEY,
  mhra: MHRA_IDENTIFIER_KEY
};

export const SORT_OPTIONS = [
  {
    label: 'Generic Name: A to Z (Default)',
    selected: true,
    field: 'generic_name',
    id: 'default',
    sortType: 'asc'
  },
  {
    label: 'Generic Name: Z to A',
    selected: false,
    field: 'generic_name',
    id: 'activeIngredientDesc',
    sortType: 'desc'
  },
  {
    label: 'Approval Year: New',
    selected: false,
    id: 'approvalYearDesc',
    field: 'approval_date',
    sortType: 'desc'
  },
  {
    label: 'Approval Year: Oldest',
    selected: false,
    id: 'approvalYearAsc',
    field: 'approval_date',
    sortType: 'asc'
  }
];

export const CT_SORT_OPTIONS: any = {
  usnlm: [
    {
      label: 'Interventions: A to Z (Default)',
      selected: true,
      field: 'interventions',
      id: 'default',
      sortType: 'asc'
    },
    {
      label: 'Interventions: Z to A',
      selected: false,
      field: 'interventions',
      id: 'interventionsDesc',
      sortType: 'desc'
    },
    {
      label: 'Completion Year: New ',
      selected: false,
      id: 'completionYearDesc',
      field: 'primary_completion_date',
      sortType: 'desc'
    },
    {
      label: 'Completion Year: Oldest ',
      selected: false,
      id: 'completionYearAsc',
      field: 'primary_completion_date',
      sortType: 'asc'
    }
  ],
  euctr: [
    {
      label: 'Interventions: A to Z (Default)',
      selected: true,
      field: 'interventions',
      id: 'default',
      sortType: 'asc',
      nestedKey: 'protocols'
    },
    {
      label: 'Interventions: Z to A',
      selected: false,
      field: 'interventions',
      id: 'interventionsDesc',
      sortType: 'desc',
      nestedKey: 'protocols'
    },
    {
      label: 'Completion Year: New ',
      selected: false,
      id: 'completionYearDesc',
      field: 'primary_completion',
      sortType: 'desc'
    },
    {
      label: 'Completion Year: Oldest ',
      selected: false,
      id: 'completionYearAsc',
      field: 'primary_completion',
      sortType: 'asc'
    }
  ]
};

const GROUP_NAME: any = {
  us: 'US - FDA - Drugs & Biologics',
  'us-devices': 'US - FDA - Devices',
  eu: 'EU - EMA - Drugs & Biologics',
  hpra: 'EU - Ireland',
  hma: 'EU - HMA',
  ca: 'CA - Drugs & Biologics',
  jp: 'JP - Drugs & Biologics',
  au: 'AU - TGA - Drugs & Biologics',
  mhra: 'UK - MHRA - Drugs & Biologics'
};

export const GROUP_BY_OPTIONS = [
  {
    label: 'Ungroup (Default)',
    selected: true,
    id: 'ungroup'
  },
  {
    label: 'Group by Source',
    selected: false,
    id: 'groupBySource',
    field: 'source',
    keySorting: 'asc',
    keyMapper: (value: any) => {
      return GROUP_NAME[value] ?? value.toUpperCase();
    }
  },
  {
    label: 'Group by Year',
    selected: false,
    id: 'groupByYear',
    keySorting: 'desc',
    field: 'approval_year'
  }
];

export const VIEW_AS_OPTIONS = [
  {
    label: 'Grid',
    selected: true,
    id: 'grid',
    icon: GridVariantIcon
  },
  {
    label: 'List',
    selected: false,
    id: 'list',
    icon: ListIcon
  }
];

export const sourceFlagMapping: any = {
  us: UsaFlagIcon,
  eu: EuFlagIcon,
  hma: EuFlagIcon,
  ca: CanadaFlagIcon,
  hpra: IrelandFlagIcon,
  ie: IrelandFlagIcon,
  'us-devices': UsaFlagIcon,
  'fda-letters': UsaFlagIcon,
  jp: JapanFlagIcon,
  adcomm: UsaFlagIcon,
  'adcomm-cdrh': UsaFlagIcon,
  'adcomm-cber': UsaFlagIcon,
  'fda-written-request': UsaFlagIcon,
  guidance: UsaFlagIcon,
  chmp: EuFlagIcon,
  'ema-pips': EuFlagIcon,
  'ema-guidance': EuFlagIcon,
  'canada-guidance': CanadaFlagIcon,
  'canada-consultation': CanadaFlagIcon,
  'ca-guidance': CanadaFlagIcon,
  ct: UsaFlagIcon,
  'dd-tools': UsaFlagIcon,
  euctr: EuFlagIcon,
  ema_orphan: EuFlagIcon,
  default: WarningIcon,
  au: AustraliaFlagIcon,
  pdco: EuFlagIcon,
  uk: UKFlagIcon,
  mhra: UKFlagIcon,
  prac: EuFlagIcon,
  'sec-edgar': UsaFlagIcon,
  'ich-guideline': IchFlagIcon,
  'ICH-Guideline': IchFlagIcon,
  arg: ArgFlagIcon,
  bra: BraFlagIcon,
  mex: MaxFlagIcon,
  egy: EgyFlagIcon,
  sgp: SgpFlagIcon,
  jpn: JpnFlagIcon,
  kor: KorFlagIcon,
  jor: JapanFlagIcon,
  chn: ChnFlagIcon,
  sau: SauFlagIcon,
  che: CheFlagIcon,
  tw: TwFlagIcon,
  tur: TurFlagIcon
};

export const sourceFlagMappingTooltip: any = {
  us: 'United States - Food & Drug Administration',
  eu: 'European Union',
  hma: 'European Union',
  ca: 'Canada - Health Canada',
  hpra: 'Ireland - Approvals by Health Products Regulatory Authority (HPRA)',
  'us-devices': 'United States - Food & Drug Administration',
  jp: 'Japan - Pharmaceuticals and Medical Devices Agency (PMDA)',
  au: 'Australia - Therapeutic Goods Administration',
  mhra: 'Medicines & Healthcare Products Regulatory Agency',
  'sec-edgar': 'United States - Securities and Exchange Commission (SEC)',
  'ich-guideline': 'International Council for Harmonisation (ICH)'
};

export const LABEL_COLOR: any = {
  nda: '#A33232',
  bla: 'blue.periwinkle',
  eua: 'yellow.brown',
  ema: 'blue.cyan',
  'ema who': 'blue.lightRoyal',
  hpra: 'orange.dark',
  denovo: 'pink.orchid',
  pma: 'blue.pastelPurpleBlue',
  pmn: 'primary.avatar',
  canada: 'red.main',
  hma: 'blue.purple',
  anda: 'blue.periwinkle',
  mhra: 'blue.purple'
};

export const LABEL_TOOLTIP_TITLE: any = {
  nda: 'New Drug Application',
  bla: 'Biologics License Application',
  eua: 'Emergency Use Authorization',
  ema: 'European Medicines Agency',
  'ema who': 'European Medicines Agency and World Health Organization',
  hpra: 'Health Products Regulatory Authority',
  denovo: 'De Novo Classification Request',
  pma: 'Pre-Market Approval',
  pmn: 'Pre-Market Notification',
  canada: '',
  hma: 'Heads of Medicines Agencies',
  mhra: 'Medicines & Healthcare Products Regulatory Agency'
};

export const CARD_COUNT_VIEWPORT_MAPPING: any = {
  xs: 1,
  sm: 1,
  md: 2,
  lg: 4,
  xl: 4
};

export const VISUALIZE_HEADER: any = {
  us: [
    {
      id: 'businessIntelligence',
      value: 'businessIntelligence',
      label: 'Business intelligence - USFDA'
    },
    {
      id: 'analyticsView',
      value: 'analyticsView',
      label: 'Analytics View'
    },
    {
      id: 'matchSubmissions',
      value: 'matchSubmissions',
      label: 'Indications First Appeared'
    },
    {
      id: 'patentsExclusivity',
      value: 'patentsExclusivity',
      label: 'Patents & Exclusivity'
    }
  ],
  eu: [
    {
      id: 'analyticsView',
      value: 'analyticsView',
      label: 'Analytics View'
    },
    {
      id: 'matchSubmissions',
      value: 'matchSubmissions',
      label: 'Indications First Appeared'
    }
  ],
  ct: [
    {
      id: 'businessIntelligenceCT',
      value: 'businessIntelligenceCT',
      label: 'Business Intelligence'
    },
    {
      id: 'timelineGantt',
      value: 'timelineGantt',
      label: 'Timeline: Gantt'
    },
    { id: 'timelineChanges', value: 'timelineChanges', label: 'Timeline: Changes' },
    { id: 'adverseEffect', value: 'adverseEffect', label: 'Adverse Effect' }
  ],
  euct: [
    {
      id: 'businessIntelligenceEUCT',
      value: 'businessIntelligenceEUCT',
      label: 'Business intelligence'
    },
    {
      id: 'timelineGanttEUCT',
      value: 'timelineGanttEUCT',
      label: 'Timeline: Gantt'
    },
    // { key: 'timelineChanges', label: 'Timeline: Changes' },
    { id: 'adverseEffectEUCT', value: 'adverseEffectEUCT', label: 'Adverse Effect' }
  ],
  ca: [
    { id: 'analyticsView', value: 'analyticsView', label: 'Analytics View' },
    {
      id: 'patentsExclusivity',
      value: 'patentsExclusivity',
      label: 'Patents & Exclusivity'
    }
  ],
  default: [{ id: 'analyticsView', value: 'analyticsView', label: 'Analytics View' }]
};
export const DOCUMENT_SEARCH_SUBTEXT: any = {
  us: ' Summary Basis of Approval',
  ca: ' CA HPFB',
  eu: ' European Public Assessment Reports',
  'us-devices': ' Summary Basis of Approval',
  devices: ' Summary Basis of Approval',
  adcomm: '  Advisory Committee',
  guidance: ' Guidance',
  'ema-guidance': ' Guidance',
  uk: ' NICE Guidance, Advice and Quality Standards',
  ct: ' US NLM ClinicalTrials.gov',
  pip: ' Paediatric Investigation Plan',
  'fda-written-request': ' FDA Written Requests',
  chmp: ' Committee for Medicinal Products for Human Use',
  hpra: ' HPRA Approval',
  ie: ' HPRA Approval',
  hma: ' Heads of Medicines Agencies',
  hpfb: ' Summary Basis of Approval',
  jp: ' Medical drugs details',
  'canada-guidance': ' Guidance',
  'canada-consultation': ' Consultation',
  'fda-letters': ' FDA Warnings and Untitled Letters',
  'Multiple source': ' Source',
  'dd-tools': ' Drug Development Tools',
  euctr: ' EU Clinical Trials Register',
  ema_orphan: 'EMA Orphan Designations',
  pdco: 'EU PDCO',
  prac: 'EU PRAC',
  sec: 'SEC',
  au: ' Australia TGA',
  ich: 'ICH Guidelines'
};

export const scoreConfidenceMapper: any = {
  answer: BestMatchIcon,
  very_high: HighScoreConfidence,
  high: HighScoreConfidence,
  medium: MediumScoreConfidence,
  low: LowScoreConfidence,
  not_available: LowScoreConfidence
};

export const scoreConfidenceMapperTooltip: any = {
  answer: 'High relevance',
  very_high: 'Good relevance',
  high: 'Good relevance',
  medium: 'Moderate relevance',
  low: 'Low relevance',
  not_available: 'Low relevance'
};

export const documentsSourceMapper: { [key: string]: string[] } = {
  us: ['CDER-SBA', 'CBER-SBA', 'CDER-EUA'],
  adcomm: ['AdComm-CDER'],
  'adcomm-cdrh': ['AdComm-CDRH'],
  'adcomm-cber': ['AdComm-CBER'],
  'fda-written-request': ['FDA Written Requests'],
  guidance: ['PSG-Guidance', 'Guidances', 'SOPP', 'MAPP'],
  'us-devices': ['CDRH-SBA', 'CDRH-EUA'],
  eu: ['EMA EPAR', 'EMA WHO'],
  hma: ['HMA'],
  chmp: ['CHMP'],
  'ema-pips': ['PIP'],
  'ema-guidance': ['EMA Guidances'],
  ca: ['HPFB'],
  'canada-guidance': ['CA Guidances'],
  'fda-letters': ['FDA Letters'],
  hpra: ['HPRA'],
  jp: ['PMDA'],
  ct: ['CLINICAL TRIALS'],
  uk: ['NICE'],
  'dd-tools': ['DDT'],
  euctr: ['EU CLINICAL TRIALS'],
  ema_orphan: ['EMA ORPHAN DESIGNATION', 'EU ORPHAN DESIGNATION'],
  'canada-consultation': ['CA Consultations'],
  au: ['Australia TGA'],
  pdco: ['PDCO'],
  mhra: ['MHRA'],
  prac: ['PRAC'],
  sec: ['SEC-EDGAR'],
  'sec-edgar': ['SEC Edgar'],
  'ich-guideline': ['ICH-Guideline']
};

// Define title, subtitle for document result as each source have different keys
export const documentCardHeadings: DocumentHeadings = {
  ...Object.assign(
    {},
    ...['EMA EPAR', 'EMA WHO', 'CDER-SBA', 'CBER-SBA', 'CDER-EUA', 'CDRH-SBA', 'PIP'].map(k => ({
      [k]: [
        { id: 'active_ingredient', label: 'Active Ingredient', placementType: 'documentTitle' },
        {
          id: 'trade_name',
          label: 'Trade Name',
          placementType: 'subtitle'
        }
      ]
    }))
  ),
  'SEC Edgar': [
    { id: 'companies', label: 'Sponsors', placementType: 'documentTitle' },
    {
      id: '_category',
      label: 'Filing Type',
      placementType: 'subtitle'
    }
  ],
  'CDRH-EUA': [
    {
      id: 'trade_name',
      label: 'Trade Name',
      placementType: 'documentTitle'
    }
  ],
  ...Object.assign(
    {},
    ...['HPFB', 'HPRA'].map(k => ({
      [k]: [
        { id: 'active-ingredient', label: 'Active Ingredient', placementType: 'documentTitle' },
        {
          id: 'trade-name',
          label: 'Trade Name',
          placementType: 'subtitle'
        }
      ]
    }))
  ),
  'CLINICAL TRIALS': [{ id: 'brief_title', label: 'Brief Title', placementType: 'documentTitle' }],
  'EU CLINICAL TRIALS': [
    { id: 'brief_title', label: 'Brief Title', placementType: 'documentTitle' }
  ],
  PMDA: [{ id: 'active-ingredient', label: 'Active Ingredient', placementType: 'documentTitle' }],
  AdComm: [{ id: 'active_ingredient', label: 'Active Ingredient', placementType: 'documentTitle' }],
  'AdComm-CDRH': [{ id: 'document-title', label: 'Title', placementType: 'documentTitle' }],
  'AdComm-CBER': [{ id: 'document-title', label: 'Title', placementType: 'documentTitle' }],
  HMA: [{ id: 'active_ingredient', label: 'Active Ingredient', placementType: 'documentTitle' }],
  ...Object.assign(
    {},
    ...[
      'Guidances',
      'CA Guidances',
      'FDA Written Requests',
      'NICE',
      'FDA Letters',
      'CA Consultations'
    ].map(k => ({
      [k]: [{ id: 'title', label: 'Title', placementType: 'documentTitle' }]
    }))
  ),
  ...Object.assign(
    {},
    ...['EMA Guidances'].map(k => ({
      [k]: [{ id: 'doc_title', label: 'Title', placementType: 'documentTitle' }]
    }))
  ),
  DDT: [{ id: 'title', label: 'Title', placementType: 'documentTitle' }],
  'Australia TGA': [
    { id: 'active_ingredients', label: 'Title', placementType: 'documentTitle' },
    { id: 'product_name', label: 'Trade Name', placementType: 'subtitle' }
  ],
  MHRA: [
    { id: 'active_ingredient', label: 'Title', placementType: 'documentTitle' },
    { id: 'product_name', label: 'Trade Name', placementType: 'subtitle' }
  ],
  ...Object.assign(
    {},
    ...['EMA ORPHAN DESIGNATION', 'EU ORPHAN DESIGNATION'].map(k => ({
      [k]: [{ id: 'document-title', label: 'Title', placementType: 'documentTitle' }]
    }))
  ),
  ...Object.assign(
    {},
    ...['PDCO'].map(k => ({
      [k]: [{ id: 'meeting-year', label: 'Title', placementType: 'documentTitle' }]
    }))
  ),
  ...Object.assign(
    {},
    ...['PRAC'].map(k => ({
      [k]: [{ id: 'meeting-year', label: 'Title', placementType: 'documentTitle' }]
    }))
  ),
  ...Object.assign(
    {},
    ...['ICH-Guideline'].map(k => ({
      [k]: [{ id: 'title', label: 'Title', placementType: 'documentTitle' }]
    }))
  )
  // Add other countries or sources as needed
};

export const SHOW_REG_360_BTN: Array<string> = [
  'us',
  'eu',
  'ca',
  'ct',
  'us-devices',
  'hpra',
  'hma',
  'jp',
  'au',
  'mhra'
];

export const dateFields = [
  'approval_date',
  'approval-date',
  'first-published',
  'submission-approval-date',
  'adcom_meeting_start_date',
  'field_issue_datetime',
  'last_published_date',
  'determination_date',
  'published',
  'start_date',
  'decision_date',
  'filing_date',
  'step_date'
];

export const sortMapping = (sortedBy: string) => {
  return [
    {
      id: 'Relevance: Very High -> Low',
      value: 'default'
    },
    { id: 'Document Date: Newest', value: sortedBy, sortType: 'desc' },
    {
      id: 'Document Date: Oldest',
      value: sortedBy,
      sortType: 'asc'
    }
  ];
};

export const sortValueMapping: any = {
  Adcomm: 'adcom_meeting_start_date',
  default: 'documentDate'
};

export const STATS_BAR: { [key: string]: any } = {
  us: [
    { field: 'generic_name', label: 'Generic Name' },
    { field: 'formulation', label: 'Formulation' }
  ],
  eu: [
    { field: 'generic_name', label: 'Generic Name' },
    { field: 'formulation', label: 'Formulation' }
  ],
  'us-devices': [
    { field: 'generic_name', label: 'Generic Name' },
    { field: 'product_code', label: 'Product Code' }
  ],
  ca: [
    { field: 'generic_name', label: 'Generic Name' },
    { field: 'formulation', label: 'Formulation' }
  ],
  hpra: [
    { field: 'generic_name', label: 'Generic Name' },
    { field: 'formulation', label: 'Formulation' }
  ],
  hma: [
    { field: 'generic_name', label: 'Generic Name' },
    { field: 'formulation', label: 'Formulation' }
  ],
  jp: [
    { field: 'generic_name', label: 'Generic Name' },
    { field: 'formulation', label: 'Formulation' }
  ],
  au: [
    { field: 'generic_name', label: 'Generic Name' },
    { field: 'formulation', label: 'Formulation' }
  ],
  mhra: [
    { field: 'generic_name', label: 'Generic Name' },
    { field: 'formulation', label: 'Formulation' }
  ]
};

export const TIPS = [
  'Use quotes to get an "EXACT" match when searching',
  'Pro Tips: Use quotes to get an "EXACT" match when searching',
  'Pro Tips: Remove unwanted results with a NOT before the word "survival NOT orr"',
  'Pro Tips: Use an asterisk a wildcard "NCT*" for flexible searches.',
  'Did you know: CDP recreates clinical development plan by linking regulatory approvals and clinical trials',
  'Pro Tips: Select relevant sources to get better results and save more time.',
  'Pro Tips: Utilize Filters at the bottom of the screen to narrow down the search results.',
  'Pro Tips: Consider narrowing search space of applications before using "Ask RIA"',
  'Pro Tips: Use OR or AND to combine and compare different search queries: "melanoma OR lymphoma"',
  'Pro Tips: Review results by using tools Visualize on the top right bar in graphical form.',
  'Did you know: RIA searches uses synonyms from Mesh terms and Vivpro dictionaries.',
  'Pro Tips: Use Timeframe in Advanced search to find information related to a specific timeframe',
  'Pro Tips: Use Reg360 view to get complete regulatory history of a product',
  'Pro Tips: Use label comparison to compare labels within and between products from all regulatory agencies',
  'Pro Tips: Advanced search is best way for finding "how many drugs are approved for...?" than the need to specify an explicit natural language question',
  'Pro Tips: Natural language questions to ARIA is a better way of finding secrets hidden in PDFs',
  'Did you know: ChatRIA can answer questions from product labels or Ask RIA-identified documents.',
  'Did you know:  RIA has translated Japanese product labels into English, users can now search in English.'
];

export const categoryMapping = {
  ...Object.assign(
    {},
    ...[
      'EMA EPAR',
      'EMA WHO',
      'CDER-SBA',
      'CDER-EUA',
      'CDRH-SBA',
      'CDRH-EUA',
      'DDT',
      'Australia TGA'
    ].map(k => ({
      [k]: 'category_bucket'
    }))
  ),
  ...Object.assign(
    {},
    ...['AdComm-CDER', 'PIP', 'HMA', 'AdComm-CDRH', 'AdComm-CBER', 'EU CLINICAL TRIALS'].map(k => ({
      [k]: 'category'
    }))
  ),
  ...Object.assign(
    {},
    ...['CA Consultations'].map(k => ({
      [k]: 'document_type'
    }))
  ),
  ...Object.assign(
    {},
    ...['NICE'].map(k => ({
      [k]: 'document_category'
    }))
  ),
  ...Object.assign(
    {},
    ...[
      'HPFB',
      'HPRA',
      'EMA Guidances',
      'Guidances',
      'PSG-Guidance',
      'Guidances',
      'CA Guidances',
      'PMDA',
      'FDA Written Requests',
      'FDA Letters',
      'SOPP',
      'MAPP',
      'PDCO',
      'MHRA',
      'PRAC',
      'SEC Edgar',
      'ICH-Guideline'
    ].map(k => ({
      [k]: '_category'
    }))
  ),
  ...Object.assign(
    {},
    ...['EMA ORPHAN DESIGNATION', 'EU ORPHAN DESIGNATION'].map(k => ({
      [k]: 'category-bucket'
    }))
  )
};

export const EMPTY_RESULTS_MESSAGES = {
  filterEmptyMessage: {
    firstMessage: 'Sorry, no results were found',
    secondMessage: 'Please try removing some filters.'
  },
  GeneralSearchEmptyMessage: {
    firstMessage: 'Sorry, no results were found here',
    secondMessage: 'Please try executing a new search.'
  }
};

export const resultTabsList = [
  {
    key: RESULT_VIEW_TYPES.DOCUMENT,
    label: 'Documents',
    tooltip: 'Search results matching query and PDF content'
  },
  {
    key: RESULT_VIEW_TYPES.APPLICATION,
    label: 'Applications',
    tooltip: 'Products matching key entity in query'
  },
  {
    key: RESULT_VIEW_TYPES.CT,
    label: 'Clinical trials',
    tooltip: 'Clinical Trials matching key entity in query'
  }
  // Disabled for now DO NOT REMOVE
  // { key: 'businessIntelligence', label: 'Business intelligence' },
  // { key: 'adcomm', label: 'AdComm' },
  // { key: 'chmp', label: 'CHMP' }
];

export const IGNORE_REG_360_BTN: Array<string> = ['adcomm'];

export const designationTypeMap: { [key: string]: string } = {
  '510(k)': 'PMN'
};

export const SOURCE_MAPPING: { [key: string]: string } = {
  us: 'US - FDA',
  eu: 'EU - EMA',
  jp: 'Japan - PMDA',
  au: 'Australia - TGA',
  mhra: 'UK - MHRA',
  hma: 'EU - HMA',
  ca: 'Canada'
};

export const DISABLED_LABEL_SECTION_SOURCES: { [key: string]: string } = {};

export const COMPARE_LABEL_TYPES = {
  ENHANCED: 'enhancedComparison',
  VIEW: 'viewLatestLabels'
};

export const COMPARE_LABEL_METHODS: any = {
  [COMPARE_LABEL_TYPES.ENHANCED]: {
    title: 'Enhanced Comparison',
    description: 'Compare any labels between products.',
    value: 'enhancedComparison',
    limit: 2,
    icon: EnhancedComparisonIcon
  },
  [COMPARE_LABEL_TYPES.VIEW]: {
    title: 'View Latest Label',
    description: 'View latest label of products side by side.',
    value: 'viewLatestLabels',
    limit: 15,
    icon: ViewLabelsIcon
  }
};

export const DEFAULT_CT_PAGINATION = {
  limit: 20,
  offset: 0
};

export const IGNORE_REGION_IN_SEARCH_SUGGESTIONS = ['uk'];

export const DOCUMENT_DATA_SOURCE_CHIP_OPTIONS: any = {
  PDCO: {
    label: 'PDCO',
    styles: { color: 'black.main' }
  },
  PRAC: {
    label: 'PRAC',
    styles: { color: 'black.main' }
  },
  CHMP: {
    label: 'CHMP',
    styles: { color: 'black.main' }
  },
  'SEC Edgar': {
    label: 'SEC',
    styles: { color: 'white.main', bgcolor: 'yellow.700' }
  }
};

export const RELEVANCE_SOURCE = {
  SUGGESTED: 'suggested',
  RELEVANT: 'relevant'
};

export const DOCUMENT_VIEW_RESULTS_PER_PAGE = 100;
