const SOURCE_MAPPING: any = {
  us: 'US FDA - Drugs & Biologics',
  eu: 'EU EMA - Drugs & Biologics',
  ct: 'CT GOV - Clinical Trials',
  ca: 'CA HPFB - Drugs & Biologics',
  'us-devices': 'US FDA - Devices',
  hpra: 'IE HPRA - Drugs & Biologics',
  hma: 'EU HMA - Drugs & Biologics',
  jp: 'JP PMDA - Drugs & Biologics'
};

export const SEARCH_TERM_COLOR = ['#4A86DF', '#D76E74', '#5DAE86', '#9873C7', '#A33232'];

export const INDICATION_SEQUENCING_VALID_SOURCE = ['us', 'eu'];
export const PATENT_VALID_SOURCE = ['us', 'ca'];

export default SOURCE_MAPPING;

export const INDICATION_TOOLTIP_TEXT = [
  {
    id: 'term',
    label: 'Search Term'
  },
  {
    id: 'approval_date',
    label: 'Approval Date'
  },
  {
    id: 'submission_class',
    label: 'Submission Class'
  },
  {
    id: 'indications_and_usage',
    label: 'Indication'
  }
];
